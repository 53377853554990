import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, concatMap, from, Observable, of, Subject, throwError } from 'rxjs';

import { catchError, filter, map, takeUntil } from 'rxjs/operators';

import { OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';

import { LoggerService } from '@app-core/services/logger.service';
import { AuthorizeService } from '@app-core/services/auth/authorize.service';
import { UtilService } from '../util.service';

import { AuthenticationConfig } from '@app-core/services/auth/authentication-config';

import { AppuserModel } from '@app-shared/models/appuser.model';
import { ClaimsModel } from './claims.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private envAuthConfig = AuthenticationConfig;

  private authenticated = new BehaviorSubject<boolean>(false);
  authenticated$ = this.authenticated.asObservable();

  constructor(
    private oauthService: OAuthService,
    private authorizationService: AuthorizeService,
    private loggerService: LoggerService,
    private router: Router,
    private utilService: UtilService
  ) {
    this.configureOAuthEvents();
  }

  private configureOAuthEvents(): void {
    // Token Receive Event
    this.oauthService.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(e => e.type === 'token_received')
      )
      .subscribe(() => {
        this.loggerService.logMessage('Authentication Service Event - Identity Token was received!');
      });

    // OAuth Error or Generic Event
    this.oauthService.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        this.loggerService.logMessage('Authentication Service Event - OAuthError Event Object', event);
      } else {
        this.loggerService.logMessage('Authentication Service Event - OAuth Event Object', event);
      }
    });

    // Session Termination and Error
    this.oauthService.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((e: OAuthEvent) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe(() => {
        this.loggerService.logMessage('Authentication Service Event - Session Terminated');
        this.logout();
      });

    // Token Identity Expires
    this.oauthService.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((e: OAuthEvent) => ['token_expires'].includes(e.type))
      )
      .subscribe(() => {
        this.loggerService.logMessage('Authentication Service Event - Token Expires');
        this.logout();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private configure(): void {
    this.oauthService.configure(this.envAuthConfig);
  }

  processIdentityProviderResponse(): Observable<AppuserModel> {
    // Return authorized user if we have valid id token
    if (this.hasValidIdToken()) {
      const authenticatedUser = this.getAuthenticatedUser();

      return this.getAuthorizedUser(authenticatedUser, false);
    }

    // Configure oauth service
    this.configure();

    // Convert promise to observable
    const tryLogin$ = from(this.oauthService.loadDiscoveryDocumentAndTryLogin());

    // Pipeline to consume identity token and authorize user
    return tryLogin$.pipe(
      takeUntil(this.unsubscribe$),
      catchError((err: HttpErrorResponse) => {
        return this.processError(err, 'AuthenticationService - Failed to authenticate user!');
      }),
      map((isDiscoveryDocumentLoaded: boolean) => {
        this.loggerService.logMessage(
          'AuthenticationService - Discovery document loaded: ' + isDiscoveryDocumentLoaded
        );

        let appuser = {
          isAuthenticated: false,
          isAuthorized: false
        } as AppuserModel;

        if (this.hasValidIdToken()) {
          this.loggerService.logMessage('AuthenticationService - Authenticating User');
          appuser = this.getAuthenticatedUser();
          this.loggerService.logItem(appuser);
        }

        return appuser;
      }),
      concatMap((authenticatedUser: AppuserModel) => {
        if (authenticatedUser.isAuthenticated && authenticatedUser.emailAddress.length > 0) {
          this.loggerService.logMessage('AuthenticationService - Authorizing User');

          return this.getAuthorizedUser(authenticatedUser, true);
        }

        return of(authenticatedUser);
      })
    );
  }

  private processError(err: HttpErrorResponse, message: string): Observable<boolean> {
    this.loggerService.logException(err, message);
    return throwError(() => err);
  }

  private getAuthorizedUser(authenticatedUser: AppuserModel, isTokenRefreshed: boolean): Observable<AppuserModel> {
    return this.authorizationService.getAuthorizedUser(authenticatedUser, isTokenRefreshed);
  }

  login(): void {
    this.oauthService.initCodeFlow();
  }

  // Triggered manually by user login out or by token expiring
  logout(): void {
    if (!this.oauthService.logoutUrl) {
      this.oauthService.configure(this.envAuthConfig);
    }
    this.oauthService.logOut();
  }

  redirectTo(url: string): void {
    this.router
      .navigateByUrl(url)
      .then(() => this.loggerService.logMessage('Authentication Service - Redirecting to:' + url));
  }

  getAuthenticatedUser(): AppuserModel {
    const appuser = {} as AppuserModel;

    if (this.hasValidIdToken()) {
      const identityClaims: ClaimsModel = this.oauthService.getIdentityClaims() as ClaimsModel;

      appuser.emailAddress = identityClaims.email;

      appuser.claims = identityClaims;

      const currentDate = this.utilService.getCurrentDate();

      appuser.identityToken = this.getIdToken();
      appuser.identityTokenExpiry = this.oauthService.getIdTokenExpiration();
      appuser.identityTokenExpiryDate = new Date(appuser.identityTokenExpiry);
      appuser.identityTokenSessionOutstandingDuration = this.getCurrentDateDifferenceInMinutes(
        currentDate,
        appuser.identityTokenExpiryDate
      );

      appuser.accessToken = this.getAccessToken();
      appuser.accessTokenExpiry = this.oauthService.getAccessTokenExpiration();
      appuser.accessTokenExpiryDate = new Date(appuser.accessTokenExpiry);
      appuser.accessTokenSessionOutstandingDuration = this.getCurrentDateDifferenceInMinutes(
        currentDate,
        appuser.accessTokenExpiryDate
      );

      appuser.isAuthenticated = true;

      appuser.isAuthenticated = true;

      this.broadcastAuthenticationStatus(true);
    }

    return appuser;
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  hasValidIdToken(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  private getCurrentDateDifferenceInMinutes(date: Date, expiryDate: Date): number {
    const timeDifferenceInMilliSeconds = expiryDate.valueOf() - date.valueOf();

    return Math.floor(timeDifferenceInMilliSeconds / 1000 / 60);
  }

  private broadcastAuthenticationStatus(status: boolean) {
    this.authenticated.next(status);
  }
}
