import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { map, takeUntil } from 'rxjs/operators';

import { HttpWrapperService } from '@app-core/services/http-wrapper.service';

import { environment } from '@app-environments/environment';
import { AppuserModel } from '@app-shared/models/appuser.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  env = environment;

  constructor(private httpWrapperService: HttpWrapperService) {}

  getAuthorizedUser(authenticatedUser: AppuserModel, isTokenRefreshed: boolean): Observable<AppuserModel> {
    return this.httpWrapperService
      .get<AppuserModel>(`${environment.apiEndPoints.appuser}\\${authenticatedUser.emailAddress}`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((authorizedUser: AppuserModel) => {
          const appuser = Object.assign(authenticatedUser, authorizedUser) as AppuserModel;
          appuser.isAuthorized = true;
          appuser.isTokenRefreshed = isTokenRefreshed;
          return appuser;
        })
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
